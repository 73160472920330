'use client';
import React, { useEffect, useRef } from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections/PortableTextComponents';
import { m, useInView } from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { AcceptedPaymentMethods } from '@/components/shared/groupIcons';
import { mixpanelProductImpression } from '@/lib/mixpanel/services/client';
import { useStatsigClient, useExperiment } from '@statsig/react-bindings';
import PriceSlash from '@/components/shared/PriceSlash';

export default function ProductBanner(props: {
  data?: {
    _key?: string;
    sectiuonId?: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    buttons?: [
      {
        text?: string;
        link: MenuItemLink;
      },
    ];
    product?: {
      productId?: string;
      productGid?: string;
      slug?: string;
      categorySlug?: string;
      price?: number;
      compareAtPrice?: number;
      sanityTitle: string;
    };
    productPage?: {
      slug: string;
    };
    backgroundColor?: string;
    image?: {
      url?: string;
      alt?: string;
      mediaAlt?: string;
      blurDataUrl?: string;
    };
    backgroundPattern?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    items?: [
      {
        text: string;
        image: {
          url: string;
          alt?: string;
          mediaAlt?: string;
          placeholderUrl?: string;
        };
      },
    ];
    showPaymentMethods?: boolean;
    useProductTitle?: boolean;
  };
}) {
  // Props
  const sectionKey = props.data?._key;
  const sectiuonId = props.data?.sectiuonId;
  const heading = props.data?.heading;
  const productTitle = props.data?.product?.sanityTitle;
  const useProductTitle = props.data?.useProductTitle;
  const buttons = props.data?.buttons;
  const subheading = props.data?.subheading;
  const image = props.data?.image;
  const items = props.data?.items;
  const product = props.data?.product;
  const color = props.data?.backgroundColor || 'orange';
  const backgroundPattern = props.data?.backgroundPattern;
  const showPaymentMethods = props.data?.showPaymentMethods;
  const section = useRef(null);
  const isInViewInitial = useInView(section, { amount: 0.4, once: true });
  const isInViewMixpanel = useInView(section, { amount: 0.5, once: true });
  const { logEvent } = useStatsigClient();
  const experimentName = 'ecom_vs_marketing_pages';
  const experiment = useExperiment(experimentName);
  const buttonText = experiment.get('CTA Button Text', 'Buy now');

  // Track product impression
  useEffect(() => {
    if (isInViewMixpanel && product) {
      mixpanelProductImpression({ product: product });
      logEvent('product_impression', product.sanityTitle, {
        event_source: 'Online',
        currency_code: 'ZAR',
        price: `${product.price}`,
        name: product.sanityTitle,
        category: product.categorySlug?.replace(/-/g, ' ') ?? '',
      });
    }
  }, [isInViewMixpanel, product]);

  // Tailwind requires classes to be defined in the component
  const colors: { [key: string]: { bg: string; bgGradient: string } } = {
    pink: {
      bg: 'bg-pink-600',
      bgGradient: 'bg-gradient-dark-pink',
    },
    orange: {
      bg: 'bg-orange-600',
      bgGradient: 'bg-gradient-orange',
    },
    teal: {
      bg: 'bg-teal-600',
      bgGradient: 'bg-gradient-teal',
    },
    white: {
      bg: 'bg-white',
      bgGradient: 'bg-gradient-white',
    },
    charcoal: {
      bg: 'bg-charcoal-900',
      bgGradient: 'bg-gradient-charcoal',
    },
  };

  const colorBg = colors[color]?.bg ?? colors['charcoal'].bg;
  const colorBgGradient =
    colors[color]?.bgGradient ?? colors['charcoal'].bgGradient;

  const getLink = (buttonText: string, button: any) => {
    if (buttonText === 'Buy now' && button.text === 'Buy now') {
      if (button.link.url === 'ik-flyer') {
        return {
          document: 'product',
          type: 'internal',
          category: 'card-machines',
          url: 'ik-flyer',
          addToCart: false,
        };
      } else if (button.link.url === 'ik-flyer-lite') {
        return {
          document: 'product',
          type: 'internal',
          category: 'card-machines',
          url: 'ik-flyer-lite',
          addToCart: false,
        };
      }
    } else if (buttonText === 'Buy now' && button.text === 'Learn more') {
      if (button.link.url === 'ik-flyer') {
        return {
          document: 'page',
          type: 'internal',
          url: 'ik-flyer',
        };
      } else if (button.link.url === 'ik-flyer-lite') {
        return {
          document: 'page',
          type: 'internal',
          url: 'ik-flyer-lite',
        };
      }
    } else if (buttonText === 'Add to cart' && button.text === 'Learn more') {
      if (button.link.url === 'ik-flyer') {
        return {
          document: 'page',
          type: 'internal',
          url: 'ik-flyer',
        };
      } else if (button.link.url === 'ik-flyer-lite') {
        return {
          document: 'page',
          type: 'internal',
          url: 'ik-flyer-lite',
        };
      }
    }
    return button.link;
  };

  return (
    <section
      id={sectiuonId}
      ref={section}
      className={`relative z-40 scroll-m-16 bg-charcoal pt-5 sm:pt-0`}
    >
      {/* Top Divider wave */}
      {!showPaymentMethods && (
        <div className='absolute left-0 top-0 h-max w-full -translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] fill-charcoal md:w-full'
            width='1464'
            height='34'
            viewBox='0 0 1464 34'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              className='fill-charcoal'
              d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
              fill='none'
            />
          </svg>
        </div>
      )}
      {/* Main section */}
      <div className='overflow-hidden pb-10 pt-0 lg:py-12'>
        <div className='container-padding container relative mx-auto'>
          <div className='mx-auto flex max-w-2xl flex-col items-center justify-start gap-8 lg:mx-0 lg:max-w-none lg:flex-row'>
            <div className='relative w-full lg:max-w-max lg:flex-auto'>
              {/* Outside Clip */}
              <div
                className={`aspect-square relative z-10 mx-auto block aspect-1 h-full w-4/5 overflow-hidden ${colorBg} object-cover [clip-path:_url(#product-info-card)] lg:h-[500px] lg:w-full`}
              >
                {/* Inside Clip */}
                <div
                  className={`aspect-square ${colorBgGradient} absolute bottom-[2px] left-[2px] right-[2px] top-[2px] z-10 block overflow-hidden bg-gradient-to-b object-cover [clip-path:_url(#product-info-card)]`}
                >
                  {image && image.url && (
                    <m.div
                      initial={{ top: '30%' }}
                      animate={{ top: isInViewInitial ? '-1%' : '30%' }}
                      variants={fadeUp}
                      transition={{
                        delay: isInViewInitial ? 0.1 : 0.3,
                        type: isInViewInitial ? 'spring' : 'tween',
                        ease: isInViewInitial ? 'easeIn' : 'easeOut',
                        bounce: 0.2,
                      }}
                      className='absolute left-0 z-20 w-11/12 shadow-black/20 drop-shadow-[-10px_11px_28px_var(--tw-shadow-color)]'
                    >
                      <Image
                        alt={image.mediaAlt || image.alt || 'iKhohka'}
                        width='400'
                        height='400'
                        priority={true}
                        placeholder='blur'
                        blurDataURL={image.blurDataUrl}
                        src={image.url}
                        className='h-full w-full object-cover'
                      />
                    </m.div>
                  )}
                  {/* Background pattern */}
                  {backgroundPattern && backgroundPattern.url && (
                    <div
                      className='absolute left-0 top-4 z-0 h-full w-full overflow-hidden rounded-3xl bg-cover md:top-20'
                      style={{
                        backgroundImage: `url(${backgroundPattern.url})`,
                      }}
                    ></div>
                  )}

                  <svg xmlns='http://www.w3.org/2000/svg' width='0' height='0'>
                    <defs>
                      <clipPath
                        id={`product-info-card`}
                        clipPathUnits='objectBoundingBox'
                        transform='scale(1.5, 1.48)'
                      >
                        <path d='M0.0800267 0.624263L0.521012 0.675214C0.564599 0.67505 0.6 0.639539 0.6 0.595917V0.0792976C0.6 0.0356748 0.564599 0.000164064 0.521012 0L0.0799538 0.0509692C0.0321565 0.0568937 0 0.0887768 0 0.130303V0.544911C0 0.565857 0.00754694 0.584268 0.0218205 0.598122C0.0358389 0.61174 0.055964 0.620782 0.0800267 0.624263Z' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className='w-full lg:max-w-xl lg:flex-auto'>
              <div className='mb-1 flex items-center gap-4'>
                {product?.compareAtPrice && (
                  <PriceSlash
                    className='flex self-center text-2xl text-yellow-150'
                    priceSlashProps={{ className: 'bg-yellow-150' }}
                    compareAtPrice={product?.compareAtPrice}
                  />
                )}
                <p className='mb-0 self-center text-2xl font-bold text-yellow-150'>
                  {formatPriceWithoutCents(product?.price ?? 0)}
                </p>
              </div>
              <h2 className='heading-xl relative z-10 font-display font-bold tracking-tighter text-yellow'>
                {heading && !useProductTitle && (
                  <PortableText
                    components={components}
                    value={heading}
                  ></PortableText>
                )}

                {useProductTitle && productTitle}
              </h2>
              <p className='mb-3 mt-0 border-b border-gray-600 pb-3 text-xl leading-8 text-white md:mb-5 md:pb-5'>
                {subheading && (
                  <PortableText
                    components={components}
                    value={subheading}
                  ></PortableText>
                )}
              </p>
              {/* Product Features */}
              {items && items.length > 0 && (
                <>
                  <h3 className='sr-only'>Product Features</h3>
                  <ul className=''>
                    {items.map((item, index) => (
                      <li
                        key={sectionKey + '-item-' + index}
                        className='-ml-2 flex items-center gap-4 py-2 text-white'
                      >
                        <Image
                          src={item.image?.url}
                          alt={
                            item.image?.mediaAlt ||
                            item.image?.alt ||
                            'Product feature'
                          }
                          width={24}
                          height={24}
                          className='h-8 w-8'
                        />
                        <span className='opacity-90'>{item.text}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {/* Action buttons */}
              <div className='flex flex-grow items-center gap-10 pt-6'>
                {buttons &&
                  buttons.length > 0 &&
                  buttons.map((button, index) => (
                    <IKButton
                      key={`category-banner-button-${index}`}
                      link={getLink(buttonText, button)}
                      color={index === 1 ? 'yellow' : 'white'}
                      // @ts-ignore
                      variant={index === 1 ? 'filled' : 'text'}
                      size={index === 1 ? 'md' : 'sm'}
                      className={`flex items-center gap-1 ${
                        index === 1
                          ? 'px-8'
                          : 'bg-gradient-line-white relative overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-2 transition-all duration-500 hover:bg-transparent hover:!bg-left-bottom hover:transition-all hover:duration-500'
                      }`}
                    >
                      <span>{button.text}</span>
                      {index === 1 && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='25'
                          viewBox='0 0 25 25'
                          fill='none'
                        >
                          <path
                            d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                            fill='currentColor'
                          />
                        </svg>
                      )}
                    </IKButton>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Methods */}
      {showPaymentMethods && (
        <div className='container-padding container relative mx-auto bg-charcoal py-12'>
          <div className='pb-4 text-center text-lg text-white'>
            All card machines accept:
          </div>
          <div className='flex justify-center'>
            <AcceptedPaymentMethods color='gray' />
          </div>
        </div>
      )}

      {/* Bottom Divider wave */}
      <div className='absolute bottom-0 left-0 h-max w-full translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-[200%] md:w-full [&_path]:fill-charcoal'
          width='1464'
          height='26'
          viewBox='0 0 1464 26'
          fill='none'
          preserveAspectRatio='none'
        >
          <path
            d='M0 0V13.4014C71.6641 8.27045 311.629 -4.51068 731.531 15.2506C1153.28 35.099 1393.51 22.1175 1464 17.0324V0H0Z'
            fill='none'
          />
        </svg>
      </div>
    </section>
  );
}
